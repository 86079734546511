const Flickity = require('flickity');
const bannerSlider = document.querySelector('.slider-banner');

if (bannerSlider) {
    let flkty = new Flickity( bannerSlider, {
        cellAlign: 'left',
        contain: true,
        // freeScroll: true,
        setGallerySize: false,
        pageDots: false,
        pauseAutoPlayOnHover: false,
        prevNextButtons: false,
        wrapAround: true,
        autoPlay: 7000,
    });

    window.addEventListener('load', () => {
        flkty.resize();
    })

    window.addEventListener('resize', () => {
        flkty.resize();
    })
}
